import React, { Component } from 'react'
import "./four-steps.css"
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby'
import Section from "../layout/section";
import { HowItWorksStrings } from '../../constants/homeStrings';

class FourStepsStaticQuery extends Component {   
    
   componentDidMount(){
      SetStepSelectedStyle(0);
   }
   
   render() {
      return (
      <StaticQuery
            query={graphql`
                  query {
                  Step1Image: file(relativePath: { eq: "how_it_works_1.png" }) {
                  ...stepImage
                  }
                  Step2Image: file(relativePath: { eq: "how_it_works_2.png" }) {
                  ...stepImage
                  }
                  Step3Image: file(relativePath: { eq: "how_it_works_3.png" }) {
                  ...stepImage
                  }
                  }
                  `}
            render={data => <FourSteps data ={ data}/>}/>
      );
   }
}

class FourSteps extends Component{
      image1;
      image2;
      image3;

      constructor(props)
      {
            super(props);
            this.image1 = this.props.data.Step1Image.childImageSharp.fluid;
            this.image2 = this.props.data.Step2Image.childImageSharp.fluid;
            this.image3 = this.props.data.Step3Image.childImageSharp.fluid;

            this.state = {image: this.image1}
      }

      SetImage = (number) =>{
            var image;
            switch(number){
                  case 0:
                        image = this.image1;
                        break;
                  case 1:
                        image = this.image2;
                        break;
                  case 2:
                        image = this.image3;
                        break;
            }
            this.setState({image: image});
      } 

      componentDidMount(){
            stepClicked(true, this.SetImage, 1);
      }

      render(){
      return(
      <Section styleName="fourStepsMain">
            <h1 className="mainHeading">{ HowItWorksStrings.title }</h1>
            <p className="mainDescription">{ HowItWorksStrings.description }</p>

            <div className="fourStepsTimeline">
                  {
                        HowItWorksStrings.stepInfo.map((value,index) => 
                              {
                                    var actualIndex = index + 1;
                                    var isLastIndex = actualIndex == HowItWorksStrings.stepInfo.length;

                                    return (
                                          <>
                                          <StepNumber Number={actualIndex} SetImage = {this.SetImage} Description={ value.title }/>
                                          <div style={{display: isLastIndex ? "none" : "initial"}} className="dashedLine"></div>
                                          </>)
                              }
                        )
                  }
            </div>
      
            <div className="fourStepsContent">
                  <div className="stepImageContainer">
                        <Img fadeIn={false} fluid={this.state.image} imgStyle={{objectFit:"contain"}} className="stepImage" />
                  </div>
                  <div className="stepInformation">
                        <p className="stepInformationHeading">{HowItWorksStrings.stepInfo[0].title}</p>
                        <p className="stepInformationDesc">{HowItWorksStrings.stepInfo[0].description}</p>
                        
                        <div className="changeStepContainer">
                              <span id= "prevStepArrow"
                              className = "material-icons prevStepArrow"
                              onClick={e => stepClicked(false, this.SetImage)}>arrow_backward</span>

                              <p className = "stepText">Step 1/3</p>

                              <span id= "nextStepArrow"
                              className = "material-icons nextStepArrow" 
                              onClick={e => stepClicked(true, this.SetImage)}>arrow_forward</span>
                        </div>
                  </div>
            </div>
      </Section>);}
}

class StepNumber extends Component {
      render(){
            return(
               <div className="stepNumberContainer">
                     <div className="stepNumberBackground" onClick={e => stepClicked(true, this.props.SetImage, this.props.Number)}>
                           {this.props.Number}
                     </div>
                     
                     <p className="stepNumberDesc">{this.props.Description}</p>
               </div>
            );
      }
}

var number = 0;
function stepClicked(isNext, setImage, stepNumber) {
      var stepText = document.getElementsByClassName("stepText");
      var prevStepArrow = document.getElementById("prevStepArrow");
      var nextStepArrow = document.getElementById("nextStepArrow");
      var isArrowClicked = stepNumber == null;
      
      SetStepSelectedStyle(number, false);
      
      if(isArrowClicked){
            if(isNext && number < 2) number++;
            if(!isNext && number > 0) number--;
      } 
      else{
            number = stepNumber-1;
      }
      SetStepSelectedStyle(number);
      stepText[0].innerHTML="Step " + (number + 1) + "/3";
      
      prevStepArrow.style.visibility = number == 0 ? 'collapse' : 'visible';
      nextStepArrow.style.visibility = number == 2 ? 'collapse' : 'visible';

      setImage(number);
      SetStepSelectedText(HowItWorksStrings.stepInfo[number].title, HowItWorksStrings.stepInfo[number].description);
}

function SetStepSelectedStyle(stepNumber, isSelected = true){
      var background = document.getElementsByClassName("stepNumberBackground");
      var desc = document.getElementsByClassName("stepNumberDesc");

      background[stepNumber].style.transition= isSelected ? "background-color 0.25s ease-out" : "background-color 0.25s ease-in";
      background[stepNumber].style.backgroundColor = isSelected ? "var(--primary-color)" : "white" ;
      background[stepNumber].style.color = isSelected ? "white" : "var(--primary-color)";
      desc[stepNumber].style.color = isSelected ? "black" : "var(--text-color-inactive)";
}

function SetStepSelectedText(headingText, descriptionText){
      var heading = document.getElementsByClassName("stepInformationHeading");
      var desc = document.getElementsByClassName("stepInformationDesc");

      heading[0].style.opacity = 0;
      desc[0].style.opacity = 0;

      setTimeout(() => {
            heading[0].innerHTML = headingText;
            desc[0].innerHTML = descriptionText;
            heading[0].style.opacity = 1;
            desc[0].style.opacity = 1;
      }, 150);

}

export default FourStepsStaticQuery;

export const stepImage = graphql`
  fragment stepImage on File {
    childImageSharp {
      fluid{
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;
