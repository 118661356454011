import React, { Component } from "react";
import "./trust-the-pros.css";
import {Link} from "@reach/router";
import {LeftImage, RightImage1, RightImage2} from '../../images/_imagesRootImport';
import Section from "../../components/layout/section";

class TrustThePro extends Component {
  render() {
    return (
      <Section styleName="trustTheProMain">
          <span className="trustTheProBackground"></span>
          <div className="trustTheProContainer" >
              <div className="leftSection">   
                    <img className="leftSectionImage" src ={LeftImage}/>              
                    <div className="leftSectionContent">
                    <h1 className="leftSectionHeading">“Interiors, crafted to perfection”</h1>
                    
                    <Link className="link" to="/about-us">
                    <button className="homeButton">ABOUT US</button>
                    </Link>
                  </div>
              </div>
        
              <div className="rightSection">
                  <div className="rightSectionTop">
                      <img className="rightSectionImage" src={RightImage1}/>
                      <img className="rightSectionImage" src={RightImage2}/>
                  </div>
                  <p className="rightSectionText">With 12+ years of Experience in Home Interiors, we aim to provide you the most transparent and convenient way of premium Quality home interiors executed by our professional craftsmen within the given time frame thereby making the process hassle free</p>
              </div>
          </div>
       </Section>
    );
  }
}

export default TrustThePro;
