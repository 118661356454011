export const HeaderStrings = {
    title: "A Home that speaks for itself",
    description: "Select from wide design range of bedroom, living room, kitchen and dining room for your room size and get the exact installation cost",
    buttonText: "browse designs"
}

export const HowItWorksStrings = {
    title: "How it Works",
    description: "Now redefining your home interiors is made hassle free with 3 easy steps :",

    stepInfo: [
        {
            title: "Select your Design", 
            description:"Choose your preferred interiors from a wide range of multiple themed designs of living room, bedroom, kitchen and dining room or opt for customization option.",
        },
        {
            title: "Calculate exact price for installation", 
            description:"Select your room size to get the exact cost of your selected design installation. We assure you of no hidden charges (contractor or designer) are involved in our process.",
        },
        {
            title: "Seal the Deal and Relax",
            description:"Just relax while our team of skilled craftsmen plans, supervise and execute your home interiors in just 8 weeks.",
        }
    ]
}

export const DesignTypesText = {
    title: "Complete Home Interiors in just 8 weeks",
    description: "Why hiring contractors for different tasks when you’re getting end-to-end home interiors on a single platform without paying 3rd party charges",
    quote: "“You Choose. We Accomplish”",
    bedrooms: "Bedrooms",
    livingRooms: "Living Rooms",
    kitchens: "Kitchen",
    diningRooms: "Dining Rooms"
}

export const WhyChooseUsText = {
    title: "Why choose Walscape for your home ?",
    reasons: [
        {
            title: "Multiple styles for same design",
            description: "We understand that every client has a unique taste for colors. We provide you the same design with multiple color styles to choose from, so that you get a home of your choice.",
            quote: "“We Work for You”"
        },
        {
            title: "8 weeks installation and warranty",
            description: "It’s our sole mission to implement your interiors within 8 weeks. We strive to relieve you from quality assurance pain, thereby insuring you with the maximum warranty to cover all your worries.",
            quote: "“No Worries, we’ve got You Covered”"
        },
        {
            title: "Transparency Unlocked",
            description: "We assure you of no third party hidden designer or contractor charges. Get instant exact free quotation and materials description used in your home interiors in the starting.",
            quote: "“Your Satisfaction, Our Goal”"
        }
    ]
}

export const TestimonialsStrings = {
    title:"Our Accomplishments!",
    description:"Our work achievements for client satisfaction are unique and measurable. We help your vision turn into reality in just 8 weeks.",
    testimonials:[
      {number:'1', name:"Dr. Renu Middha", testimonialText:"Walscape gave us numerous design options to fulfill our requirements. Really a new way to design the interiors on time. Very cost efficient services", imageData: null},
      {number:'2', name:"Mr. Sushil Dadhich", testimonialText:"Probably one of the best interior solutions. Their quality of work is amazing and very timely delivery as promised to us. Really happy with services", imageData: null},
      {number:'3', name:"Mr.Chandan Sharma", testimonialText:"Cost optimized interior services. Thank you for understanding us and providing us so many options till we were very satisfied", imageData:null},
      {number:'4', name:"Mrs. Khurana", testimonialText:"You guys are just awesome, completed all finishings 5 days before deadline. You guys are very friendly and professional. Thanks a lot", imageData:null},
      {number:'5', name:"Mr. Viklesh Middha", testimonialText:"We started with Walscape with one bedroom interiors and ended up having the entire house renovated. Best Home interior solution with full cooperation and transparency", imageData:null}
  ]}

export const ContactDetailsStrings = {
    title:  "Stand Out from the Rest",
    description: "Get in touch with us to design your home with the best in Industry.",
    quote: "We Create your Dreams!"
}

