import React, { Component } from "react";
import "./drop-message.css";
import ContactDetails from "../../components/common/contact_details";
import ContactForm from "../../components/common/contact-form";
import PopUp, {OpenSuccessPopUp} from "../../components/common/pop-up";
import Section from "../../components/layout/section";
import { ContactDetailsStrings } from "../../constants/homeStrings";

class DropMessage extends Component {
  render() {
    return (
      <Section styleName="dropMessageContainer">
          <PopUp/>

          <div className="dropMessageContent">
              <div className="contactUsLeft">
                  <div className="contactUsText">
                      <h1 className="mainHeading">{ContactDetailsStrings.title}</h1>
                      <p className="mainDescription">{ContactDetailsStrings.description}</p>
                      <p className="quote" id="dropMessageQuote">{ContactDetailsStrings.quote}</p>
                  </div>
                  <ContactDetails color="white"/>
              </div>
          
              <ContactForm Action={()=>{OpenSuccessPopUp();}} pageName = "home-page"/>
          </div>
      </Section>
    );
  }
} 

export default DropMessage;


