import React, {Component} from "react";
import "./design-solution.css";
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby'
import { Link } from "gatsby";
import Section from "../../components/layout/section";
import { DesignTypesText } from "../../constants/homeStrings";

class DesignSolution extends Component {
  render() {
    return (
      <StaticQuery
      query={graphql`
      query {
        bedroomCardImage: file(relativePath: { eq: "bedroom_card_image.jpg" }) {
          ...productCardImage
        }
        livingRoomCardImage: file(relativePath: { eq: "living_room_card_image.jpg" }) {
          ...productCardImage
        }
        kitchenCardImage: file(relativePath: { eq: "kitchen_card_image.jpg" }) {
          ...productCardImage
        }
        diningRoomCardImage: file(relativePath: { eq: "dining_room_card_image.jpg" }) {
          ...productCardImage
        }
      }
      `}
      render={data => 
      <Section styleName="designSolutionsMain">
          <div className="designSolutionsBackground"></div>
          <div className="designSolutionsContainer">
              <h1 className="mainHeading" id="designSolutionsMainHeading">{ DesignTypesText.title }</h1>
              <p className="mainDescription" id="designSolutionsMainDescription"> { DesignTypesText.description }</p>
              <p className="quote" id="designSolutionsQuote">{ DesignTypesText.quote }</p>

              <div className="designSolutionsContent">
                  <div className="designSolutionsContentBackground"></div>
                  <div className="designSolutionsContentContainer">
                    <ProductCard name= {DesignTypesText.bedrooms} link="/bedroom-designs" imageData={data.bedroomCardImage}/>
                    <ProductCard name= {DesignTypesText.livingRooms} link="/living-room-designs" imageData={data.livingRoomCardImage}/>
                    <ProductCard name= {DesignTypesText.kitchens} link="kitchen-designs" imageData={data.kitchenCardImage}/>
                    <ProductCard name= {DesignTypesText.diningRooms} link="dining-room-designs" imageData={data.diningRoomCardImage}/>
                  </div>
              </div>
          </div>
      </Section>}
      />
    );
  }
}

function ProductCard(props) {
  return (
    <div className="productCardContainer">
        <Link className="link" to ={props.link}>
            <div>
            <Img fluid={props.imageData.childImageSharp.fluid} alt="" className="productCardImage"/>
            <p className="productTitle">{props.name}</p>  
            </div>
        </Link>
    </div>
  );
}

export default DesignSolution;

export const productCardImage = graphql`
  fragment productCardImage on File {
    childImageSharp {
      fluid(maxWidth: 480, maxHeight: 720) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;