import Layout from "../components/layout/layout.js"
import {Home, FourSteps, WhyChooseUs, TrustThePro,
    DropMessage, DesignSolution, HappyCustomers} from "../components/index/_indexRootImport";
import SEO from '../components/seo'
import React, { Component } from 'react'
import { HomeTags } from "../components/layout/strings.js";

class Index extends Component{
  render(){
    return(
      <Layout>
          <SEO 
            title={HomeTags.title}
            description={HomeTags.description} />
          <Home/>
          <FourSteps/>
          <DesignSolution/>
          <WhyChooseUs/>
          <TrustThePro/>
          <HappyCustomers/>
          <DropMessage/>
      </Layout>
    );
  }
}

export default Index