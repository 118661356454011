import React, { Component } from "react";
import "./home.css"
import { Link, graphql } from 'gatsby'
import Section from "../../components/layout/section";
import { HeaderStrings } from "../../constants/homeStrings";

class Home extends Component{ 
  render(){
    return(
        <Section styleName="homeContainer">
            <div className='homeContent'>
                <h1 id="homeHeaderText" className="pageHeader">{ HeaderStrings.title }</h1>
                <p id="homeMainDescription" className="mainDescription">{ HeaderStrings.description }</p>
                <Link className="homeLink" to='/living-room-designs'>
                    <button className="homeButton">{ HeaderStrings.buttonText.toUpperCase() }</button>
                </Link>
            </div>
      </Section>
    )
  }
}

export default Home