import React, { Component } from "react";
import "./happy-customer.css";
import Img from 'gatsby-image';
import { StaticQuery, graphql } from 'gatsby'
import Section from "../../components/layout/section";
import { TestimonialsStrings } from "../../constants/homeStrings";

class HappyCustomersStaticQuery extends Component {
  render() {
    return (
      <StaticQuery
      query={graphql`
      query {
        firstUserImage1: file(relativePath: { eq: "first_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        firstUserImage2: file(relativePath: { eq: "first_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        firstUserImage3: file(relativePath: { eq: "first_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        firstUserImage4: file(relativePath: { eq: "first_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        firstUserImage5: file(relativePath: { eq: "first_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage1: file(relativePath: { eq: "second_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage2: file(relativePath: { eq: "second_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage3: file(relativePath: { eq: "second_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage4: file(relativePath: { eq: "second_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage5: file(relativePath: { eq: "second_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage6: file(relativePath: { eq: "second_user_image6.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage7: file(relativePath: { eq: "second_user_image7.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage8: file(relativePath: { eq: "second_user_image8.jpg" }) {
          ...happyCustomersImageFragment
        }
        secondUserImage9: file(relativePath: { eq: "second_user_image9.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage1: file(relativePath: { eq: "third_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage2: file(relativePath: { eq: "third_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage3: file(relativePath: { eq: "third_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage4: file(relativePath: { eq: "third_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage5: file(relativePath: { eq: "third_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage6: file(relativePath: { eq: "third_user_image6.jpg" }) {
          ...happyCustomersImageFragment
        }
        thirdUserImage7: file(relativePath: { eq: "third_user_image7.jpg" }) {
          ...happyCustomersImageFragment
        }
        fourthUserImage1: file(relativePath: { eq: "fourth_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        fourthUserImage2: file(relativePath: { eq: "fourth_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        fourthUserImage3: file(relativePath: { eq: "fourth_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        fourthUserImage4: file(relativePath: { eq: "fourth_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        fourthUserImage5: file(relativePath: { eq: "fourth_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage1: file(relativePath: { eq: "fifth_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage2: file(relativePath: { eq: "fifth_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage3: file(relativePath: { eq: "fifth_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage4: file(relativePath: { eq: "fifth_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage5: file(relativePath: { eq: "fifth_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage6: file(relativePath: { eq: "fifth_user_image6.jpg" }) {
          ...happyCustomersImageFragment
        }
        fifthUserImage7: file(relativePath: { eq: "fifth_user_image7.jpg" }) {
          ...happyCustomersImageFragment
        }
        sixthUserImage1: file(relativePath: { eq: "sixth_user_image1.jpg" }) {
          ...happyCustomersImageFragment
        }
        sixthUserImage2: file(relativePath: { eq: "sixth_user_image2.jpg" }) {
          ...happyCustomersImageFragment
        }
        sixthUserImage3: file(relativePath: { eq: "sixth_user_image3.jpg" }) {
          ...happyCustomersImageFragment
        }
        sixthUserImage4: file(relativePath: { eq: "sixth_user_image4.jpg" }) {
          ...happyCustomersImageFragment
        }
        sixthUserImage5: file(relativePath: { eq: "sixth_user_image5.jpg" }) {
          ...happyCustomersImageFragment
        }
      }
      `}
      render={data => <HappyCustomers data={data}/>}
      />
    );
  }
}

class HappyCustomers extends Component {
  constructor(props){
    super(props)
    TestimonialsStrings.testimonials[0].imageData = [this.props.data.firstUserImage1.childImageSharp.fluid, this.props.data.firstUserImage2.childImageSharp.fluid, this.props.data.firstUserImage3.childImageSharp.fluid, this.props.data.firstUserImage4.childImageSharp.fluid, this.props.data.firstUserImage5.childImageSharp.fluid];
    TestimonialsStrings.testimonials[1].imageData = [this.props.data.secondUserImage1.childImageSharp.fluid, this.props.data.secondUserImage2.childImageSharp.fluid, this.props.data.secondUserImage3.childImageSharp.fluid, this.props.data.secondUserImage4.childImageSharp.fluid, this.props.data.secondUserImage5.childImageSharp.fluid, this.props.data.secondUserImage6.childImageSharp.fluid, this.props.data.secondUserImage7.childImageSharp.fluid, this.props.data.secondUserImage8.childImageSharp.fluid, this.props.data.secondUserImage9.childImageSharp.fluid];
    TestimonialsStrings.testimonials[2].imageData = [this.props.data.thirdUserImage1.childImageSharp.fluid, this.props.data.thirdUserImage2.childImageSharp.fluid, this.props.data.thirdUserImage3.childImageSharp.fluid, this.props.data.thirdUserImage4.childImageSharp.fluid, this.props.data.thirdUserImage5.childImageSharp.fluid, this.props.data.thirdUserImage6.childImageSharp.fluid, this.props.data.thirdUserImage7.childImageSharp.fluid];
    TestimonialsStrings.testimonials[3].imageData = [this.props.data.fourthUserImage1.childImageSharp.fluid, this.props.data.fourthUserImage2.childImageSharp.fluid, this.props.data.fourthUserImage3.childImageSharp.fluid, this.props.data.fourthUserImage4.childImageSharp.fluid, this.props.data.fourthUserImage5.childImageSharp.fluid];
    TestimonialsStrings.testimonials[4].imageData = [this.props.data.fifthUserImage1.childImageSharp.fluid, this.props.data.fifthUserImage2.childImageSharp.fluid, this.props.data.fifthUserImage3.childImageSharp.fluid, this.props.data.fifthUserImage4.childImageSharp.fluid, this.props.data.fifthUserImage5.childImageSharp.fluid, this.props.data.fifthUserImage6.childImageSharp.fluid, this.props.data.fifthUserImage7.childImageSharp.fluid];
  }
  componentDidMount(){
    TestinomialSelected(3);
  }
  render() {
    return (
      <Section styleName="happyCustomerMain">
              <h1 className="mainHeading" id="happyCustomerMainHeading">{TestimonialsStrings.title}</h1>
              <p className="mainDescription" id="happyCustomerMainDescription">{TestimonialsStrings.description}</p>
              <div id="happyCustomerContent" className="happyCustomerContent" onScroll={e=>shouldTestimonialBeClickable=false}>
                  <div className="happyCustomerContentContainer">
                  {TestimonialsStrings.testimonials.map(value => {return <Testimonial data={value}/>})}
                  </div>
              </div>
              <div className='dotsContainer'>
                  {TestimonialsStrings.testimonials.map(value => {return <span className="dot" onClick={e => TestinomialSelected(value.number)}></span>})}
              </div>
      </Section>
    );
  }
}

class Testimonial extends Component{
  render(){
    var marginOnRight = this.props.data.number == "5" ? '10vw' : '4vw';
    var marginOnLeft = this.props.data.number == "1" ? '10vw' : '0vw';
    return(  
      <div className="testimonialContainerContainer">
      <ImageViewer Images = {this.props.data.imageData} Number ={this.props.data.number}/>
        <div id={this.props.data.number} className='testimonialContainer'
          style={{marginRight:marginOnRight, marginLeft:marginOnLeft}} onClick={e=> TestinomialSelected(this.props.data.number)}>
            <Img className="thirdImage" fluid={this.props.data.imageData[0]}/>

            <div className="columnEnd">
                <div className="userName">
                    <h3 className="customerName">{this.props.data.name}</h3>
                    <p className="quote">{this.props.data.testimonialText}</p>
                    <button className="viewAllPics" onClick={()=> ShowImageViewer(this.props.data.number)}>View all</button>
                </div>

                <Img className="fifthImage" fluid={this.props.data.imageData[1]} style={{position:"relative"}}/>
            </div>
        </div>
      </div>
    );
  }
}

var currentImage=0;
class ImageViewer extends Component {
  constructor(props) {
    super(props);
    this.state = {image: this.props.Images[0]};
  }
  render(){
    return <div id={"imageViewer" + this.props.Number} className="imageViewer">
    <button className="closeImageViewerButton" onClick={() => HideImageViewer(this.props.Number)}>&times;</button>
    <button className="changeImageButton" onClick={this.ShowPrevImage.bind(this)}>&#10094;</button>
    <Img className="imageViewerImage" fluid={this.state.image} fadeIn={false}/>
    <button className="changeImageButton" onClick={this.ShowNextImage.bind(this)}>&#10095;</button>
  </div>;
  }

  ShowPrevImage = () => {
    currentImage = currentImage > 0 ? currentImage - 1 : this.props.Images.length - 1;
    var image = {image: this.props.Images[currentImage]}
    this.setState(image);
  }

  ShowNextImage = () => {
    currentImage = currentImage < this.props.Images.length - 1 ? currentImage + 1 : 0;
    var image = {image: this.props.Images[currentImage]}
    this.setState(image);
  }
}

function ShowImageViewer(number)
{
  var imageViewer = document.getElementById("imageViewer" + number);
  imageViewer.style.display = "flex";    
}

function HideImageViewer(number)
{
  var imageViewer = document.getElementById("imageViewer" + number);
  imageViewer.style.display = "none";    console.log(imageViewer);
}

var currentTestimnial=1;
var shouldTestimonialBeClickable = false;
function TestinomialSelected(number){
    var happyCustomerContent= document.getElementById('happyCustomerContent');
    var testimonialContainer= document.getElementById(number);
    var testimonialContainerBoxShadow= document.getElementById(currentTestimnial);
    var dots = document.getElementsByClassName("dot");

    if (shouldTestimonialBeClickable && currentTestimnial == number) return;
    happyCustomerContent.scroll({
    left: (window.innerWidth * 0.84)*(number-1),
    behavior:'smooth'
    })
    dots[currentTestimnial-1].className ="dot";
    dots[number-1].className +=" active";
    testimonialContainerBoxShadow.classList.replace('testimonialContainerBoxShadow', 'testimonialContainer');
    testimonialContainer.classList.replace('testimonialContainer', 'testimonialContainerBoxShadow');
    currentTestimnial=number;
    shouldTestimonialBeClickable=true;
}

export default HappyCustomersStaticQuery;

export const happyCustomersImageFragment = graphql`
  fragment happyCustomersImageFragment on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;