import React, { Component } from "react";
import "./why-choose-us.css";
import { Image360, ImageWarranty, ImageTransparency } from "../../images/_imagesRootImport";
import Section from "../../components/layout/section";
import { WhyChooseUsText } from "../../constants/homeStrings";

class WhyChooseUs extends Component {
  render() {
    return (
      <Section styleName="chooseUsMain">
          <div className="chooseUsContainer">
              <h1 className="mainHeading" id="chooseUsMainHeading">{WhyChooseUsText.title}</h1>

              <div className="chooseUsReasons">
                <ChooseUs name={WhyChooseUsText.reasons[0].title}
                          description={WhyChooseUsText.reasons[0].description}
                          quote={WhyChooseUsText.reasons[0].quote}
                          chooseUsImage={Image360}/>

                <ChooseUs name={WhyChooseUsText.reasons[1].title}
                          description={WhyChooseUsText.reasons[1].description}
                          quote={WhyChooseUsText.reasons[1].quote}
                          chooseUsImage={ImageWarranty}/>
                          
                <ChooseUs name={WhyChooseUsText.reasons[2].title}
                          description={WhyChooseUsText.reasons[2].description}
                          quote={WhyChooseUsText.reasons[2].quote}
                          chooseUsImage={ImageTransparency}/>      
              </div>
          </div>
      </Section>
    );
  }
}

function ChooseUs(props){
  return(
    <div className="reason">
        <div className="reasonInformation">
            <img className="chooseUsImage" src={props.chooseUsImage}/>

            <p className="chooseUsHeading">{props.name}</p>
            <p className="chooseUsInformation">{props.description}</p>
            <p className="quote">{props.quote}</p>
        </div>
    </div>
  );
}
export default WhyChooseUs;
